const CONFIG = {
  APIURL: "https://api.3elixir.com:9002/",

  CONTACT_US_URL: "api/v1/savecontact",
  BOOK_CONSULTATION_URL: "api/v1/quoteuser",

  TEMPLATE_TYPE: [
    { name: "Web Development", value: 'web-development' },
    { name: "Mobile Development", value: 'mobile-development' },
    { name: "Consultation", value: 'consultation' },
  ],
};

export default CONFIG;
